<template>
  <div>
    <b-form @submit.prevent="submitUpdate" enctype="multipart/form-data">
      <b-card no-body class="mb-1">
        <b-card-header>
          <div>
            <b-card-title class="mb-1"> Dados fiscais </b-card-title>
            <b-card-sub-title>
              Os dados inseridos abaixo serão utilizados na emissão de nota
              fiscal.
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body class="pl-2 pr-2">
          <div class="form-row">
            <div class="col-md-3">
              <div class="form-group">
                <label for="">
                  CNPJ/CPF
                </label>
                <b-input-group>
                  <input class="form-control" type="text" v-mask="['###.###.###-##', '##.###.###/####-##']"
                    v-model="item.document" />
                </b-input-group>
              </div>
            </div>

            <div class="col-md-2">
              <div class="form-group">
                <label for="">
                  IE
                  <i class="bi bi-question-circle" v-b-tooltip.hover.top="'A inscrição estadual é o registro das empresas perante a Receita Estadual.'
                    "></i>
                </label>
                <input type="text" v-model="item.state_license" class="form-control" />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for=""> IM
                  <i class="bi bi-question-circle" v-b-tooltip.hover.top="'A inscrição municipal É a identificação do contribuinte no Cadastro Tributário Municipal.'
                    "></i>
                </label>
                <input type="text" v-model="item.city_license" class="form-control" />
              </div>
            </div>

            <div class="col-md-2">
              <div class="form-group mb-0">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Simples Nacional
                </label>
                <v-select label="title" :searchable="false" item-text="title" item-value="code"
                  v-model="item.simple_national" placeholder="Digite o nome" :options="[
                    { title: 'Sim', code: 1 },
                    { title: 'Não', code: 0 },
                  ]" :class="{ 'is-invalid': $v.item.simple_national.$error }">
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group mb-0">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Nome Fantasia
                  <i class="bi bi-question-circle" v-b-tooltip.hover.top="'Nome de fantasia ou apelido'"></i>
                </label>
                <input type="text" :class="{ 'is-invalid': $v.item.company_name.$error }" v-model="item.company_name"
                  class="form-control" />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group mb-0">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Nome
                  <i class="bi bi-question-circle" v-b-tooltip.hover.top="'Nome registrado na junta comercial ou nome completo do contato.'
                    "></i>
                </label>
                <input type="text" :class="{ 'is-invalid': $v.item.trading_name.$error }" v-model="item.trading_name"
                  class="form-control" />
              </div>
            </div>

            <div class="col-md-2">
              <div class="form-group mb-md-0">
                <label for=""> Abertura </label>
                <input type="text" placeholder="dd/mm/aaaa" v-model="item.date" v-mask="['##/##/####']"
                  class="form-control" />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group mb-md-0">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Situação
                </label>
                <v-select label="title" item-text="title" item-value="code" :searchable="false" v-model="item.active"
                  :options="optionsStatus" :class="{ 'is-invalid': $v.item.active.$error }">
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>

      <b-tabs pills class="mb-2">
        <b-tab active>
          <template #title>
            <span class="d-none d-sm-inline">Contatos</span>
          </template>

          <b-card no-body class="mb-1">
            <b-card-body class="pl-2 pr-2">
              <div class="form-row">
                <div class="col-md-6">
                  <div class="form-group mb-0 mb-m-1">
                    <label for=""> E-mail </label>
                    <input type="email" v-model="item.email" class="form-control" />
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group mb-0 mb-m-1">
                    <label for=""> Telefone </label>
                    <input type="tel" v-mask="['(##) ####-####', '(##) #####-####']" v-model="item.phone"
                      class="form-control" />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group mb-0 mb-m-1">
                    <label for=""> Celular </label>
                    <input type="tel" v-mask="['(##) ####-####', '(##) #####-####']" v-model="item.cellphone"
                      class="form-control" />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group mb-0 mb-m-1">
                    <label for=""> WhatsApp </label>
                    <input type="tel" v-model="item.whatsapp" v-mask="['(##) ####-####', '(##) #####-####']"
                      class="form-control" />
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-tab>

        <b-tab>
          <template #title>
            <span class="d-none d-sm-inline">Endereço</span>
          </template>
          <b-card no-body class="mb-1">
            <b-card-body class="pl-2 pr-2">
              <div class="form-row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label for=""> CEP </label>
                    <input type="text" class="form-control" @keyup="searchCep()" v-mask="['#####-###']"
                      v-model="item.zipcode" />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Número</label>
                    <input type="text" v-model="item.number" class="form-control" />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Complemento</label>
                    <input type="text" v-model="item.complement" class="form-control" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">Endereço</label>
                    <input type="text" v-model="item.address" class="form-control" />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group mb-0 mb-m-1">
                    <label for="">Bairro</label>
                    <input type="text" v-model="item.neighborhood" class="form-control" />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group mb-0 mb-m-1">
                    <label for="">Cidade</label>
                    <input type="text" v-model="item.city" class="form-control" />
                  </div>
                </div>
                <div class="col-md-1">
                  <div class="form-group mb-0 mb-m-1">
                    <label for="">Estado</label>
                    <input type="text" v-model="item.state" class="form-control" />
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-tab>
      
        <b-tab>
          <template #title>
            <span class="d-none d-sm-inline">Observações</span>
          </template>
          <b-card no-body class="mb-1">
            <b-card-header>
              <div>
                <b-card-title class="mb-1"> Observações gerais </b-card-title>
                <b-card-sub-title>
                  Caso tenha mais alguma informação ou observação sobre a empresa
                </b-card-sub-title>
              </div>
            </b-card-header>
            <b-card-body class="pl-2 pr-2">
              <textarea v-model="item.note" class="d-block form-control" rows="4"></textarea>
            </b-card-body>
          </b-card>
        </b-tab>
    
        <b-tab>
          <template #title>
            <span class="d-none d-sm-inline">Contas bancárias</span>
          </template>
          <div class="card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions">
            <div class="card-header mb-0">
              <div class="form-row">
                <div class="col-md-11">
                  <h4 class="mb-0">
                    Contas bancárias
                    <small class="d-block mt-50">Vincule 1 ou mais contas bancárias a esta empresa.</small>
                  </h4>
                </div>
                <div class="col-md-1 d-flex-left justify-content-end" style="column-gap: 5px;">
                  <button type="button" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    style="height: 33px; padding: 0px!important" @click="addAccountBank"
                    class="btn-block btn btn-sm btn-primary">
                    <i class="bi bi-plus"></i>
                  </button>
                </div>
              </div>

              <div class="form-row align-items-center mt-1" :class="{ 'mb-1': i !== item.bank_accounts.length - 1 }" v-for="(el, i) in item.bank_accounts" :key="`business-strategic-key-${i}`">
                <div class="col-md-11">
                  <div class="form-group mb-0">
                    <v-select label="title" v-model="el.bank_account" item-text="title" item-value="code"
                      placeholder="Conta bancária" :options="optionsBankAccounts">
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>

                <div class="col-md-1">
                  <div class="form-group mb-0">
                    <button type="button" class="btn btn-sm btn-danger btn-block" style="padding: 14px 10px !important;" @click="deleteBankAccount(i)">
                      <i class="bi bi-trash3"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>

      <div class="form-row justify-content-end">
        <div class="col-md-2">
          <button type="submit" class="btn btn-block btn-success">
            <div v-if="submited">
              <b-spinner small variant="light" /> Verificando...
            </div>
            <div v-else>Salvar</div>
          </button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
  BSpinner,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BCardBody,
  BInputGroup,
  BFormInput,
  VBTooltip,
  BInputGroupAppend,
  BForm,
} from "bootstrap-vue";

import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";

import Ripple from "vue-ripple-directive";

import BtnSaveAndBack from "@/components/button/SaveAndBack";

import { required } from "vuelidate/lib/validators";

import axios from "axios";

import vSelect from "vue-select";
import "swiper/css/swiper.css";

export default {
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  components: {
    BCard,
    BSpinner,
    BForm,
    BtnSaveAndBack,
    BCardHeader,
    BCardBody,
    BCardTitle,
    VBTooltip,
    BCardSubTitle,
    BFormCheckbox,
    vSelect,
    BTabs,
    BTab,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    ButtonsActionsFooter,
  },
  data() {
    return {
      uuid: "",
      docs: [],
      submited: false,
      item: {
        company_name: "",
        trading_name: "",
        category: "",
        type: "",
        simple_national: "",
        docs: [],
        business: true,
        date: "",
        email: "",
        state_license: "",
        city_license: "",
        phone: "",
        cellphone: "",
        whatsapp: "",
        address: "",
        complement: "",
        city: "",
        state: "",
        number: "",
        zipcode: "",
        neighborhood: "",
        status: "",
        note: "",
        bank_accounts: [],
      },
      optionsBankAccounts: [],
      optionsStatus: [
        {
          title: "Ativo",
          code: 1,
        },
        {
          title: "Inativo",
          code: 0,
        },
      ],
    };
  },
  methods: {
    addAccountBank() {
      this.item.bank_accounts.push({ id: '' })
    },
    deleteBankAccount (id) {
      this.item.bank_accounts.splice(id, 1);
    },
    async getData() {
      this.$store.dispatch("Company/edit", this.uuid).then((res) => {
        this.item = res;
      });
    },
    searchCNPJ() {
      axios
        .get(
          `https://receitaws.com.br/v1/cnpj/${this.item.document.replace(
            /\D/g,
            ""
          )}`
        )
        .then((res) => {
          console.log(res);
        });
    },
    searchCep() {
      if (this.item.zipcode.length == 9) {
        axios
          .get(`https://viacep.com.br/ws/${this.item.zipcode}/json/`)
          .then((res) => {
            const data = res.data;

            this.item.address = data.logradouro;
            this.item.neighborhood = data.bairro;
            this.item.city = data.localidade;
            this.item.state = data.uf;
          })
          .catch((error) => console.log(error));
      }
    },
    submitUpdate() {
      this.$v.item.$touch();
      this.submited = true;

      if (!this.$v.item.$error) {
        const formData = {
          ...this.item,
          active: this.item.active ? this.item.active.code : "",
          simple_national: this.item.simple_national ? this.item.simple_national.code : ""
        }

        this.$store
          .dispatch("Company/update", { uuid: this.uuid, data: formData })
          .then(() => {
            this.notifyDefault("success");
            this.$router.push({ name: "companies-list" });
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
  },
  validations: {
    item: {
      company_name: {
        required,
      },
      trading_name: {
        required,
      },
      simple_national: {
        required,
      },
      active: {
        required,
      },
    },
  },
  async mounted() {
   
    this.uuid = this.$route.params.uuid;

    this.optionsBankAccounts = await this.$store.dispatch("BankAccount/forSelect", {
     term: ''
    })

    this.getData();
  },
};
</script>
